import React , {useEffect, useState} from 'react'
import PlayerComponent from '../components/playerComponent/reactplayer'
import '../styles/index.css'
import Button from '../components/buttonComponent/Button'
import { navigate } from 'gatsby-link'
import { useAuth } from '../context/contextProvider'
import {GetHomeVideo} from '../firebase/getVideo'

function Index() {
  const auth = useAuth()
  const [videoLink, setvideoLink] = useState()
  useEffect(() => {
    GetHomeVideo().then(res => {
      setvideoLink(res[0]?.url)
    })
  }, [])
  return (
    <div className="index">
        <h1 className='index-title' onClick={() => navigate('/steps')}>Bienvenue à {auth?.stationInfo?.Station_Name}</h1>
        <PlayerComponent VideoLink={videoLink}/>
        <div onClick={() => navigate('/steps')} style={{width: "100%"}}>
          <Button name="Suivant" Theme ={{borderRadius : "5px",background : "#DD1D21", color: "#FBFBFB", float : 'right', margin: "20px 20px 20px auto"}} verify={() => navigate('/steps')}/>
        </div>
    </div>
  )
}
export default Index
