import React from 'react'
import './reactplayer.css'
import ReactPlayer from 'react-player'

export default function reactplayerComponent(props) {
    return (
        <div className='player-wrapper'>
        <ReactPlayer
          controls
          className='react-player'
          url={props.VideoLink}
          width='100%'
          height="100%"
        />
        </div>
    )
}
